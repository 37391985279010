import { IMedia } from './media.type'

export interface IAccount {
  id: string
  description: string
  account_holder: string
  image?: string
  image_small?: string
  image_medium?: string
  address?: string
  country?: string
  region?: string
  latitude?: string
  longitude?: string
  website?: string
  email?: string
  socials?: {
    x?: string
    facebook?: string
    instagram?: string
    linkedin?: string
    youtube?: string
  }
  media: Array<IMedia>
  hide_name: boolean
  hide_product_name: boolean
  map_satellite_view: boolean
  show_unsgd_home_page: boolean
  show_unsgd_impacts_page: boolean
  show_wallet_address: boolean
  show_bc_info: boolean
  wallet_address: string
  analytics_code: string | null
  ai_generated: boolean

  //from whith_lable
  primary_color: string | null
  secondary_color: string | null
  alternative_color: string | null
  footer: string | null
  footer_url: string | null
  footer_color: string | null
  qr_code_logo: string | null
}

export enum EDefaultLandingPage {
  HOME = 'home',
  JOURNEY = 'journey',
  IMPACT = 'impact',
  ACCOUNT = 'account',
}
