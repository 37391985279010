import { styled } from '@mui/material'
import { COLORS } from 'src/styles/common'

export const TopDivider = styled('div')<{
  bgColor?: string
}>`
  width: 100%;
  height: 22px;
  background-color: ${(props) => props?.bgColor || COLORS.PRIMARY};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`
