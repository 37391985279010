import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAccount, useProduct } from 'src/hooks'

import { Loader, PageFooter, PageWrapper, ProductCard, ProductImage } from 'src/components'
import { TopDivider } from './product.styled'
import { LayoutContainer } from 'src/components/layout-container'
import { setProductMetadata } from 'src/utils'
import { Typography, useTheme } from '@mui/material'
import { FlaskIcon } from 'src/assets/icons/v2/FlaskIcon'

export default function ProductPage() {
  const [t, i18n] = useTranslation()
  const { productId, simpleProductId } = useParams()
  const theme = useTheme()
  const { data: product, isLoading: isProductLoading } = useProduct(
    productId,
    i18n.language,
    simpleProductId,
  )

  const { data: account, isLoading: isAccountLoading } = useAccount(
    product?.account_id,
    i18n.language,
  )

  useEffect(() => {
    if (product && product?.name) {
      setProductMetadata({
        title: `Trackgood Journey - ${product.name}`,
        product: {
          name: product.name,
        },
      })
      document.title = `Trackgood Journey ${product?.name}`
    }
  }, [product?.name])

  if (!product || !account || isAccountLoading || isProductLoading) {
    return <Loader />
  }

  return (
    <LayoutContainer>
      {(account?.alternative_color || product?.ai_generated) && (
        <TopDivider bgColor={account.alternative_color}>
          {account?.ai_generated && (
            <>
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: 700,
                  color: theme.palette.common.white,
                  mt: '2px',
                }}
                textAlign={'center'}
              >
                {t('ai-generated-label')}
              </Typography>
              <FlaskIcon size={14} />
            </>
          )}
        </TopDivider>
      )}
      <PageWrapper>
        {/* <ProductImageV2 image={product.image} media={product.mainMedia} />
        <ProductCardV2 product={product} /> */}

        <ProductImage
          name={product.name}
          image={product.image}
          media={product.mainMedia}
          unSdg={account?.show_unsgd_home_page ? product.un_sdg : []}
        />

        <ProductCard product={product} primaryColor={account.primary_color} />
      </PageWrapper>

      <PageFooter />

      {/* <FooterV2 /> */}
    </LayoutContainer>
  )
}
