import { WalletAddress } from '../wallet-address'
import { AvatarSt, AvatarWrapper, ContainerSt, LocationSt, TitleSt } from './account-info.styled'

export const AccountInfo = ({
  accountName,
  image,
  walletAddress,
  showWalletAddress,
  country,
  region,
}) => {
  return (
    <ContainerSt>
      <AvatarWrapper>
        <AvatarSt src={image} alt={accountName} />
        <div>
          <TitleSt variant="h2">{accountName}</TitleSt>
          {country && (
            <LocationSt variant="body2">{`${country}${region ? ', ' + region : ''}`}</LocationSt>
          )}
          {showWalletAddress && (
            <LocationSt>
              <WalletAddress address={walletAddress} />
            </LocationSt>
          )}
        </div>
      </AvatarWrapper>
    </ContainerSt>
  )
}
