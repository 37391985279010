import { useTheme } from '@mui/material'

interface IProps {
  size?: number | string
  stroke?: string
}

export const FlaskIcon = ({ size, stroke }: IProps) => {
  const theme = useTheme()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 24}
      height={size ?? 24}
      viewBox="0 0 24 24"
      fill="none"
      stroke={stroke ?? theme.palette.common.white}
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      className="icon icon-tabler icons-tabler-outline icon-tabler-flask"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M9 3l6 0" />
      <path d="M10 9l4 0" />
      <path d="M10 3v6l-4 11a.7 .7 0 0 0 .5 1h11a.7 .7 0 0 0 .5 -1l-4 -11v-6" />
    </svg>
  )
}
