import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  PageWrapper,
  Loader,
  ImpactsCardsVer,
  PreviousPage,
  NextPage,
  PageContainer,
  PageFooter,
} from 'src/components'
import { useAccount, useImpacts } from 'src/hooks'
import { TopDivider } from '../product/product.styled'
import { LayoutContainer } from 'src/components/layout-container'
import { DEFAULT_LANGUAGE, PATHS, ROUTES } from 'src/constants'
import { useEffect } from 'react'
import { Typography, useTheme } from '@mui/material'
import { FlaskIcon } from 'src/assets/icons/v2/FlaskIcon'

export default function ImpactsPage() {
  const [t, i18n] = useTranslation()
  const { simpleProductId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()

  const { impacts, isLoading: isImpactsLoading, product } = useImpacts()
  const { data: account, isLoading: isAccountLoading } = useAccount(
    product?.account_id,
    i18n.language,
  )

  useEffect(() => {
    if (!isImpactsLoading && product?.name) {
      document.title = `Trackgood Journey ${product?.name} Impacts`
    }
  }, [isImpactsLoading, product?.name])

  useEffect(() => {
    const impactId = location.hash?.substring(1) // Get the impact ID from the URL hash

    if (!isImpactsLoading && impacts?.length > 0) {
      if (impactId) {
        // Delay the scroll to ensure elements are rendered
        setTimeout(() => {
          const impactElement = document.getElementById(impactId)
          if (impactElement) {
            const elementPosition = impactElement.getBoundingClientRect().top + window.scrollY
            const offsetPosition = elementPosition - 35

            // Scroll directly to the calculated position
            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            })
          } else {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }
        }, 10)
      }
    }
  }, [isImpactsLoading, impacts, location.hash])

  useEffect(() => {
    if (impacts && impacts.length === 0 && !isImpactsLoading) {
      if (location.state?.to) {
        navigate(
          i18n.language === DEFAULT_LANGUAGE
            ? `/${ROUTES.ACCOUNT}/${product?.account_simple_id}`
            : `/${i18n.language}/${ROUTES.ACCOUNT}/${product?.account_simple_id}`,
          { state: { from: location.pathname, productId: simpleProductId } },
        )

        return
      }
      navigate(location.pathname.replace(`/${PATHS.IMPACTS}`, ''))
      return
    }
  }, [impacts])

  if (!product || isImpactsLoading || isAccountLoading || !account) {
    return <Loader />
  }

  return (
    <LayoutContainer>
      {(account?.alternative_color || product?.ai_generated) && (
        <TopDivider bgColor={account.alternative_color}>
          {account?.ai_generated && (
            <>
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: 700,
                  color: theme.palette.common.white,
                  mt: '2px',
                }}
                textAlign={'center'}
              >
                {t('ai-generated-label')}
              </Typography>
              <FlaskIcon size={14} />
            </>
          )}
        </TopDivider>
      )}

      <PageWrapper>
        <PageContainer>
          {/* <div className={SwiperButtonsHolder}>
            <div className={SwiperButton} onClick={handlePrevious}>
              <LeftArrowIcon />
            </div>
            <LocaleSelectV2 />
            <div className={EmptyButton} />
          </div>
          <ImpactsCardV2 impacts={impacts} product={product} /> */}
          <PreviousPage
            to={
              location.state?.from
                ? location.state?.from
                : location.pathname.replace(PATHS.IMPACTS, PATHS.JOURNEY)
            }
            absolute
          />
          <NextPage
            to={
              i18n.language === DEFAULT_LANGUAGE
                ? `/${ROUTES.ACCOUNT}/${product?.account_simple_id}`
                : `/${i18n.language}/${ROUTES.ACCOUNT}/${product?.account_simple_id}`
            }
            state={{ from: location.pathname, productId: simpleProductId }}
          />
          <ImpactsCardsVer
            items={impacts}
            showUnSdg={account?.show_unsgd_impacts_page}
            primaryColor={account.primary_color}
            walletAddress={account.wallet_address}
            showWalletAddress={account.show_wallet_address}
            showBcInfo={account.show_bc_info}
            accountImage={account?.image_small || account?.image_medium}
          />
        </PageContainer>
      </PageWrapper>
      <PageFooter />
      {/* <FooterV2 /> */}
    </LayoutContainer>
  )
}
