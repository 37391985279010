import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import { useState } from 'react'

import { IMedia, IProduct } from 'src/types'
import { MediaList } from '../media-list'
import { DetailsContainer, DetailsDescription, DetailsTitle } from './account-details.styled'
import { css } from '@emotion/css'
import { Box, Divider, Tooltip, Typography, TextField, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import { TitleText, ProductsWrapper, ProductItem } from './account-additional-styles.styled'
import { useStyles } from './style'
import { DEFAULT_LANGUAGE } from 'src/constants'
import { FlaskIcon } from 'src/assets/icons/v2/FlaskIcon'

interface IProps {
  description: string
  media: IMedia[]
  allProducts: IProduct[]
}

export const AccountDetails = ({ description, media, allProducts }: IProps) => {
  const [t, i18n] = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const [searchTerm, setSearchTerm] = useState('')

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const filteredProducts = allProducts.filter(
    (product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (product?.gtin || '').toLowerCase().includes(searchTerm.toLowerCase()),
  )

  return (
    <DetailsContainer>
      <DetailsTitle variant="subtitle1">{t('about-us-label')}</DetailsTitle>
      {description && description !== '<p><br></p>' ? (
        <DetailsDescription variant="body2" sx={{ fontSize: '14px' }}>
          {parse(description)}
        </DetailsDescription>
      ) : null}

      {media.length >= 1 && description && description !== '<p><br></p>' && (
        <Divider sx={{ borderColor: 'rgba(0,0,0,0.08);' }} />
      )}
      {media.length > 0 && (
        <>
          <MediaList media={media} />
          <Divider sx={{ borderColor: 'rgba(0,0,0,0.08);' }} />
        </>
      )}
      {/* <ProductsList products={products} /> */}
      {allProducts && !!allProducts.length && (
        <>
          <div className={TitleText}>{t('product-list-title')}</div>
          {allProducts.length > 1 && (
            <TextField
              label={t('account-products-search-placeholder')}
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{ marginBottom: '20px', width: '50%', minWidth: '200px' }}
            />
          )}
          <div className={ProductsWrapper}>
            {filteredProducts.map((el) => (
              <Box
                key={el.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '120px',
                  alignItems: 'center',
                  gap: '4px',
                  position: 'relative',
                }}
              >
                {el?.ai_generated && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      left: 0,
                      height: '22px',
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: '4px 4px 0px 0px',

                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '6px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '10px',
                        fontWeight: 700,
                        color: theme.palette.common.white,
                        mt: '2px',
                      }}
                      textAlign={'center'}
                    >
                      {t('ai-generated-label')}
                    </Typography>
                    <FlaskIcon size={14} />
                  </Box>
                )}
                <Tooltip key={el.id} title={el.name} PopperProps={{ className: classes.popper }}>
                  <Link
                    className={css(
                      ProductItem,
                      `background-image: url('${el.image_medium ?? el.image_small ?? el.image}');`,
                    )}
                    to={
                      i18n.language === DEFAULT_LANGUAGE
                        ? `/${el.simple_id}`
                        : `/${i18n.language}/${el.simple_id}`
                    }
                  ></Link>
                </Tooltip>
                <Typography
                  sx={{ color: '#000', fontWeight: 600, textAlign: 'center', mt: '3px' }}
                  variant="body2"
                >
                  {el.name}
                </Typography>
                {el?.gtin && (
                  <Typography sx={{ fontSize: '10px' }} variant="body2">
                    {el.gtin}
                  </Typography>
                )}
              </Box>
            ))}
          </div>
        </>
      )}
    </DetailsContainer>
  )
}
